export default {
    PublicLiability: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number',
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    InsuranceBackedGuarantee: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    ProductLiability: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'nsurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number'
        },
        proof: {
            label: 'Proof of Certificate',
            type: 'file '
        }
    },
    ProfessionalIndemnity: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    StructuralWarranty: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    PlantEquipment: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    ContractorsAllRisk: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    EmployerLiability: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    },
    Other: {
        name: {
            label: 'Insurer Name',
            type: 'input'
        },
        policyNumber: {
            label: 'Insurance Policy Number',
            type: 'input'
        },
        expiryDate: {
            label: 'Expiry Date',
            type: 'date'
        },
        sum: {
            label: 'Insurance Sum',
            type: 'number'
        },
        proof: {
            label: 'Proof Of Certificate',
            type: 'file'
        }
    }
};
