import { defineComponent } from 'vue';
import RatingProgress from '@/core/components/ratings/RatingProgress.vue';
import RatingStars from '@/core/components/ratings/RatingStars.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
export default defineComponent({
    name: 'profile-summary-component',
    components: {
        UserThumbnail,
        RatingStars,
        RatingProgress,
    },
    props: {
        profile: {
            required: true,
            type: Object
        },
        rating: {
            type: Number,
            default: 0
        },
        ratingSummary: {
            type: Object,
            required: true,
        },
        popoverRating: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            profileImage: null,
            colors: ['#264FD5', '#264FD5', '#264FD5'],
            avgRating: 0,
            totalRatings: 0,
        };
    },
    created() {
        this.avgRating = this.ratingSummary.avgRating || 0;
        this.totalRatings = this.ratingSummary.totalRatings || 0;
    }
});
