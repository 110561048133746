import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CertificateFormItemComponent from '@/modules/account-profile/components/certificates/CertificateFormItemComponent.vue';
import CertificateFieldMapping from '@/static/template-property-mapping/certificate-template';
import { CERTIFICATES_STORE } from '../../../store/modules/certificates';
export default defineComponent({
    name: 'profile-certificates-component',
    components: {
        CertificateFormItemComponent,
    },
    props: {
        certificates: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        }
    },
    data: () => {
        return {
            templateLoaded: false,
            CertificateFieldMapping,
        };
    },
    computed: {
        ...mapGetters(CERTIFICATES_STORE, ['templates']),
    },
    methods: {
        ...mapActions(CERTIFICATES_STORE, ['getCertificates']),
    },
    created() {
        this.getCertificates()
            .then(() => {
            this.templateLoaded = true;
        });
    }
});
