import { defineComponent } from 'vue';
export default defineComponent({
    name: 'profile-business-detail-component',
    props: {
        profile: {
            required: true,
            type: Object
        }
    },
});
