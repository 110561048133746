import { defineComponent } from 'vue';
import RatingProgress from '@/core/components/ratings/RatingProgress.vue';
import ReviewRatingCard from '@/core/components/ratings/RatingReviewCard.vue';
import RatingStars from '@/core/components/ratings/RatingStars.vue';
import ProfileGalleryComponent from '@/modules/account-profile/components/ProfileGalleryComponent.vue';
import ProfileSummaryComponent from '@/modules/account-profile/components/ProfileSummaryComponent.vue';
export default defineComponent({
    name: 'customer-card-component',
    components: {
        ProfileSummaryComponent,
        RatingStars,
        RatingProgress,
        ReviewRatingCard,
        ProfileGalleryComponent,
    },
    props: {
        ratings: Array,
        ratingSummary: Object,
        paginate: Object,
        paginateMeta: Object,
        paginatePage: Function,
        profile: {
            required: true,
            type: Object
        },
    },
    data() {
        return {
            search: '',
        };
    },
    computed: {
        profileGallery() {
            return this.profile.userPublicProfile && this.profile.userPublicProfile.galleries
                ? this.profile.userPublicProfile.galleries
                : [];
        }
    },
    methods: {
        onSearchText(value) {
            this.$emit('on-search', value);
        }
    },
});
