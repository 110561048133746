import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_GALLERY_CACHE } from '@/store/modules/attachment-cache/constants';
import { API_URL } from '../../../config';
export default defineComponent({
    name: 'profile-gallery-component',
    props: {
        images: {
            type: Array,
            required: true,
        },
        profile: Object
    },
    data() {
        return {
            galleryImages: [],
            apiUrl: API_URL,
        };
    },
    created() {
        const { images } = this.$props;
        this.loadGalleryImageUrls(images);
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        async loadGalleryImageUrls(list) {
            this.getAttachments({
                name: USER_PUBLIC_GALLERY_CACHE,
                attachments: list
            })
                .then((values) => {
                this.galleryImages = values.map((value) => value.src);
            }).catch(() => { });
        }
    }
});
