import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProfileSkills from '@/core/components/account/ProfileSkills.vue';
import ProfileAboutComponent from '@/modules/account-profile/components/ProfileAboutComponent.vue';
import ProfileBusinessDetailComponent from '@/modules/account-profile/components/ProfileBusinessDetailComponent.vue';
import ProfileCertificatesComponent from '@/modules/account-profile/components/ProfileCertificatesComponent.vue';
import ProfileGalleryComponent from '@/modules/account-profile/components/ProfileGalleryComponent.vue';
import ProfileInsurancesComponent from '@/modules/account-profile/components/ProfileInsurancesComponent.vue';
import ProfileSummaryComponent from '@/modules/account-profile/components/ProfileSummaryComponent.vue';
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'profile-card-component',
    components: {
        ProfileSkills,
        ProfileSummaryComponent,
        ProfileBusinessDetailComponent,
        ProfileAboutComponent,
        ProfileCertificatesComponent,
        ProfileInsurancesComponent,
        ProfileGalleryComponent,
    },
    props: {
        profile: {
            required: true,
            type: Object
        },
        ratingSummary: Object,
    },
    data() {
        return {
            userSkills: {
                categories: []
            },
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(PROJECTS_STORE, ['categories']),
        profileGallery() {
            return this.profile.userPublicProfile && this.profile.userPublicProfile.galleries
                ? (this.profile.userPublicProfile.galleries)
                : [];
        },
        getSkills() {
            const data = [];
            this.userSkills.categories.forEach((category) => {
                const catObj = {};
                const parentCategory = this.categories.find((c) => c.id === category[0]);
                if (parentCategory && parentCategory.subCategories) {
                    catObj.parentCategory = JSON.parse(JSON.stringify(parentCategory));
                    delete catObj.parentCategory.children;
                    catObj.subCategory = parentCategory.subCategories.find((sub) => sub.id === category[1]);
                    data.push(catObj);
                }
            });
            return data;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getCategories']),
        ...mapActions(USERS_STORE, ['getUserSkills']),
        setUserSkillsRecord(userSkills) {
            const userSkillsRecord = {
                categories: [],
            };
            if (userSkills.length) {
                userSkills.forEach((record) => {
                    userSkillsRecord.categories.push([
                        record.subCategory.category.id,
                        record.subCategory.id
                    ]);
                });
                this.userSkills = userSkillsRecord;
            }
        },
    },
    async created() {
        const { userId } = this.$route.params;
        await this.getCategories();
        const userSkills = await this.getUserSkills(userId);
        // set the user skills record
        this.setUserSkillsRecord(userSkills);
    }
});
