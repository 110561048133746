import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PAGE_LIMIT } from '@/core/constants';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileCardComponent from '@/modules/account-profile/components/ProfileCardComponent.vue';
import ProfileReviewCardComponent from '@/modules/account-profile/components/ProfileReviewCardComponent.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'account-profile-page',
    components: {
        ProfileCardComponent,
        ProfileReviewCardComponent,
    },
    data: () => {
        return {
            submittingForm: false,
            UserVerificationStatusEnum,
            loadedData: false,
            selectedTab: 'profile',
            showRatingDialog: false,
            currentTab: 'profile',
            ratings: [],
            user: null,
            ratingSummary: {
                avgRating: 0,
                totalRatings: 0,
                ratings: []
            },
            pageFilters: {
                page: 1,
                limit: PAGE_LIMIT
            },
            // holds a copy of the page from response data
            pageListMetadata: {
                page: 0,
                limit: PAGE_LIMIT,
                totalCount: 0,
                totalPages: 0,
            },
        };
    },
    async created() {
        this.user = await this.getUser(this.$route?.params.userId);
        const ratingSummary = await this.getUserRatingSummary(this.$route?.params.userId);
        this.ratingSummary = ratingSummary;
        this.fetchUserRatingList();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getUser',
            'createUserRating',
            'getUserRatingsPageList',
            'getUserRatingSummary'
        ]),
        async fetchUserRatingList(filter = {}) {
            const currentFilters = {
                ...this.pageFilters,
                ...filter,
                ...{
                    toUserId: this.user.id
                }
            };
            const ratingsPageList = await this.getUserRatingsPageList({
                userId: this.user.id,
                filter: currentFilters
            });
            this.ratings = ratingsPageList.data;
            const { totalCount, totalPages } = ratingsPageList;
            this.pageListMetadata = { ...this.pageListMetadata, totalCount, totalPages };
        },
        onSearchText(value) {
            // reset the page filter to 1 when doing new query
            this.pageFilters.page = 1;
            this.fetchUserRatingList({
                search: value
            });
        },
        paginatePage(currentpage) {
            this.pageFilters.page = currentpage;
            this.fetchUserRatingList();
        }
    }
});
