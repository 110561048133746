import { Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_CERTIFICATES_CACHE } from '@/store/modules/attachment-cache/constants';
export default defineComponent({
    name: 'certificate-form-item-component',
    components: {
        Picture
    },
    props: [
        'propName',
        'metadata',
        'certificate'
    ],
    data() {
        return {
            file: null
        };
    },
    created() {
        const { attachment } = this.certificate;
        if (attachment && attachment.length) {
            this.loadAttachment(attachment[0]);
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        async loadAttachment(value) {
            this.getAttachments({
                name: USER_CERTIFICATES_CACHE,
                attachments: [value],
                returnFirstOnly: true
            })
                .then(({ src }) => {
                this.file = src;
            }).catch(() => { });
        }
    }
});
