import { defineComponent } from 'vue';
import InsuranceFormItemComponent from '@/modules/account-profile/components/insurances/InsuranceFormItemComponent.vue';
import InsuranceFieldMapping from '@/static/template-property-mapping/insurance-template';
export default defineComponent({
    name: 'profile-insurances-component',
    props: {
        insurances: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        }
    },
    components: {
        InsuranceFormItemComponent,
    },
    data() {
        return {
            templateLoaded: false,
            InsuranceFieldMapping
        };
    },
});
